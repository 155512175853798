<template>
  <v-container v-show="showForm">
    <the-page-title
      title="OTKAZIVANJE ZAHTJEVA ZA UPIS"
      :steps="false"
    />
    <the-child-info
      :name="child_name"
      :surname="child_surname"
      :pin="child_pin"
      :email="email"
    />
    <v-row class="justify-center">
      <div class="pa-6 pt-4 pb-4 mb-8 elevation-6 rounded fluid nbr-mw800">
        <v-checkbox
          :dense="denseField"
          v-model="form.cancel.value"
          :error-messages="form.cancel.error"
          @change="form.cancel.error=''"
          label="Potvrđujem otkazivanje zahtjeva za upis djeteta u vrtić"
        />
      </div>
    </v-row>
    <v-row class="justify-center">
      <v-card class="pa-2 elevation-6 blue lighten-3 lighten-4 nbr-mw800 red">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="postForm"
          >Otkaži zahtjev</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ThePageTitle from '../components/ThePageTitle'
import TheChildInfo from '../components/TheChildInfo';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'Cancel',
  mixins: [ common ],
  created () {
    this.getFormData();
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    }
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormCancel', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.child_name = res.data.form_basic.child_name;
            this.child_surname = res.data.form_basic.child_surname;
            this.child_pin = res.data.form_basic.child_pin;
            this.email = res.data.form_basic.email;
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => { this.hideOverlay(); this.showForm = true; });
    },
    postForm() {
      this.showOverlay();
      axios.post('', { action: 'saveFormCancel', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.showInfo({ title: 'OBAVIJEST', text: res.data.message, button: true });
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash},
        cancel: { value: false, error: '' }
      },
      //
      child_name: null,
      child_surname: null,
      child_pin: null,
      email: null,
      showForm: false
    }
  },

  components: {
    TheChildInfo,
    ThePageTitle
  }
};

</script>