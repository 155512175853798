<template>
  <v-container v-show="showForm">
    <the-page-title
      title="PODATCI O DJETETU"
      :steps="steps"
    />
    <the-child-info
      :name="child_name"
      :surname="child_surname"
      :pin="child_pin"
      :email="email"
    />
    <v-row class="justify-center">
      <div class="pa-6 pt-8 pt-sm-4 pb-0 mb-3 mb-sm-8 elevation-6 rounded fluid nbr-mw800">
        <div v-for="(item, index) in form" :key="item.name" :index="index">
          <v-select
            class="mb-3 mb-sm-0"
            v-if="item.type=='select' && item.tags=='g1'"
            :dense="denseField"
            :outlined="outlined"
            :label="item.label"
            :items="item.items"
            v-model="form[index].value"
            :error-messages="form[index].error"
            @change="form[index].error=''"
            return-object
          />
          <v-scale-transition>
          <v-text-field
            class="mb-3 mb-sm-0"
            v-if="item.type=='text' && item.tags=='g1' && checkVisible(form[item.visible[0]].value, item.visible[1])"
            :dense="denseField"
            :outlined="outlined"
            :label="item.label"
            v-model="form[index].value"
            :error-messages="form[index].error"
            @keypress.native="form[index].error=''"
          />
        </v-scale-transition>
          <city-search
            class="mb-3 mb-sm-0"
            v-if="index=='child_city_n' && checkVisible(form[item.visible[0]].value, item.visible[1])"
            :dense="denseField"
            :outlined="outlined"
            v-model="form[index].value"
            :error-messages="form[index].error"
            @change="form[index].error=''"
          />
        </div>
        <v-scale-transition>
        <div v-if="child_family_status > 119">
          <div v-for="(item, index) in document_list_o" :key="item.document_type_code + 'o1'" :index="index">
            <v-scale-transition>
              <file-upload
                class="mb-3 mb-sm-0"
                v-if="index=='family_D116' && form[index].visible"
                :dense="denseField"
                :hash="form.hash.value"
                :max="item.max_files"
                :label="item.document_type_label"
                :note="item.document_note"
                :error-messages="form[index].error"
                @error="showError"
                v-model="form[index].value"
                @change="form[index].error=''"
              />
            </v-scale-transition>
            <v-scale-transition>
              <file-upload
                class="mb-3 mb-sm-0"
                v-if="index=='family_D108' && form[index].visible"
                :dense="denseField"
                :hash="form.hash.value"
                :max="item.max_files"
                :label="item.document_type_label"
                :note="item.document_note"
                :error-messages="form[index].error"
                @error="showError"
                v-model="form[index].value"
                @change="form[index].error=''"
              />
            </v-scale-transition>
          </div>
        </div>
        </v-scale-transition>
        <div class="mt-3"></div>
        <file-upload
          v-for="(item, index) in document_list" :key="item.document_type_code" :index="index"
          :dense="denseField"
          :hash="form.hash.value"
          :max="item.max_files"
          :label="item.document_type_label"
          :error-messages="form[item.document_type_code].error"
          @error="showError"
          v-model="form[item.document_type_code].value"
          @change="form[item.document_type_code].error=''"
        />
        <div v-if="p93">
          <div v-for="(item, index) in form" :key="item.name" :index="index" class="mb-2">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g2'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              return-object
            />
            <v-scale-transition>
            <v-text-field
              class="mb-3 mb-sm-0"
              v-if="item.type=='text' && item.tags=='g2' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @keypress.native="form[index].error=''"
            />
            </v-scale-transition>
          </div>
        </div>
      </div>
    </v-row>

    <v-row class="justify-center mb-4">
      <v-card class="pa-2 elevation-6 blue nbr-mw800">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="postForm('b')"
          >Spremi podatke o djetetu</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ThePageTitle from '../components/ThePageTitle';
import CitySearch from '../components/CitySearch';
import TheChildInfo from '../components/TheChildInfo';
import FileUpload from '../components/FileUpload';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'SignA',
  mixins: [ common ],
  mounted () {
    this.getFormData();
    console.log('Hash: ' + this.form.hash.value);
    console.log(this.form);
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    }
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormA', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.child_name = res.data.form_basic.child_name;
            this.child_surname = res.data.form_basic.child_surname;
            this.child_pin = res.data.form_basic.child_pin;
            this.email = res.data.form_basic.email;
            this.document_list = res.data.document_list;
            this.document_list_o = res.data.document_list_o;
            this.p93 = eval(res.data.p93);
            this.steps = res.data.steps;

            this.setFormFields(res.data.fields);
            for (const item in res.data.document_list) {
              this.$set(this.form, item, { value: null, error: null });
            }
            for (const item in res.data.document_list_o) {
              this.$set(this.form, item, { value: null, error: null });
            }

            this.form.child_city.items = res.data.city_items;
            this.setFormData(res.data.form_a);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => {
          this.init = false;
          this.hideOverlay();
          this.showForm = true;
        });
    },
    showError(m) {
      this.showDialog({ text: m, app: true });
    },
    postForm(route) {
      this.showOverlay();
      axios.post('', { action: 'saveFormA', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            console.log('Route change to: ' + 'prijava/' + this.form.hash.value + '/' + route);
            this.$router.push('/prijava/' + this.form.hash.value + '/' + route);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    }
  },

  watch: {
    'form.child_city.value.value' (_, o) {
      if (o == -1) {
        this.form.child_city_n.value = null;
      }
    },
    'form.child_family_status.value.value': { immediate: false, handler(n) {
        console.log(n);
        this.child_family_status = n;
        if (typeof n !== 'undefined') {
          this.form['family_D116'].visible = false;
          this.form['family_D108'].visible = false;
          if (!this.init) {
            this.resetFormField('family_D116');
            this.resetFormField('family_D108');
            this.resetFormField('child_family_status_note');
          }

          if (n == 120 || n == 130) {
            if (!this.init) {
              this.form['family_D116'].value = [];
            }
            this.form['family_D116'].visible = true;
          }
          else if (n == 121 || n == 131) {
            if (!this.init) {
              this.form['family_D108'].value = [];
            }
            this.form['family_D108'].visible = true;
          }
        }
      }
    },
    'form.vacctinated.value.value' (n, o) {
      if (n != o && !this.init) {
        this.resetFormField('vacctinated_note');
      }
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash },
        // dummy variable to handle null visible_condition field
        _at: { value: true }
      },
      //
      child_name: null,
      child_surname: null,
      child_pin: null,
      email: null,
      document_list: null,
      document_list_o: null,
      outlined: false,
      showForm: false,
      p93: null,
      steps: null,
      child_family_status: null,
      init: true
    }
  },

  components: {
    TheChildInfo,
    ThePageTitle,
    FileUpload,
    CitySearch
  }
};

</script>