import VueRouter from 'vue-router';
import Home from './pages/Home.vue';
import Sign from './pages/Sign.vue';
import SignA from './pages/SignA.vue';
import SignB from './pages/SignB.vue';
import SignC from './pages/SignC.vue';
import Document from './pages/Document.vue';
import Questionnaire from './pages/Questionnaire.vue';
import Cancel from './pages/Cancel.vue';
import Review from './pages/Review.vue';
import SignView from './pages/SignView.vue';

import Vue from 'vue';
import { publicPath } from '../vue.config'
Vue.use(VueRouter);

const sign = {
  path: '/prijava/:hash',
  component: Sign,
  children: [
    { path: '', redirect: 'a'  } ,
    { path: 'a', component: SignA } ,
    { path: 'b', component: SignB },
    { path: 'c', component: SignC },
    { path: 'upitnik', component: Questionnaire },
    { path: 'dokumenti', component: Document },
    { path: 'otkazi', component: Cancel },
    { path: 'provjera-i-potvrda', component: Review },
    { path: 'detalji-zahtjeva', component: SignView }
  ]
};

const home = {
  path: '/',
  component: Home
};

const catchAll = {
  path: '*',
  redirect: '/'
};

const routes = [
  sign,
  home,
  catchAll
];

const router = new VueRouter({
  base: publicPath,
  routes,
  mode: 'history'
});

export default router;