import { mapActions } from 'vuex';

const common = {
  methods: {
    ...mapActions([
      'showOverlay',
      'hideOverlay',
      'showInfo',
      'hideInfo',
      'hideHideInfo',
      'showDialog',
      'hideDialog',
      'showApp',
      'hideApp'
    ]),
    cmnAxiosPost(res) {
      this.resetFormValidation();
      // sign finished or not started
      if (res.data.status == 199) {
        this.showInfo({ title: 'OBAVIJEST', text: res.data.message });
        return false;
      }
      // validation error
      if (res.data.status == 100) {
        this.validateForm(res.data.data);
        return false;
      }
      // validation error, prethodne forme
      if (res.data.status == 101) {
        let text = 'Greška u validaciji.<br>';
        text += 'Nije moguće potvrditi ovaj korak jer prethodni koraci nisu dobro upisani.<br>';
        text += 'Pregledajte i provjerite jeste li upisali ispravno sve prethodne korake u prijavi.<br>';
        this.showDialog({ text, app: true });
        return false;
      }
      // other errors
      else if (res.data.status > 0 && res.data.status < 100) {
        this.showDialog({ text: res.data.message, app: true });
        return false;
      }
      return true;
    },
    cmnAxiosError(error, showApp) {
      console.log(error);
      this.showDialog({ text: error, app: showApp });
    },
    formData() {
      var result = {};
      for (const f in this.$data.form) {
        result[f] = this.$data.form[f].value;
      }
      return result;
    },
    resetFormValidation() {
      for (const f in this.$data.form) {
        this.$data.form[f].error = '';
      }
    },
    validateForm(res) {
      let count = 0;
      for (const f in this.$data.form) {
        if (f in res) {
          this.$data.form[f].error = res[f];
          count++;
        }
        else {
          this.$data.form[f].error = '';
        }
      }

      let text = 'Greška u validaciji.<br>';
      text += 'Pregledajte jeste li upisali dobro sva polja.<br>';
      text += 'Crvenom bojom je označeno polje koje nije ispravno upisano.';

      if (count) {
        this.showDialog({ text, app: true });
      }
    },
    setFormFields(fields) {
      for (let i=0; i<fields.length; i++) {
        // default is always visible (dummy form variable)
        let visible = [ '_at', true ];
        let items = null;
        let value = null;
        let level = 0;
        if (fields[i].type == 'select') { value = { value: null, text: null } }
        if (fields[i].type == 'multiselect') { value = [] }
        if (fields[i].items) { items = JSON.parse(fields[i].items) }
        if (fields[i].visible) {
          visible = JSON.parse(fields[i].visible);
          level = 1;
        }
        this.$set(this.form, fields[i].name, {
          value,
          error: null,
          label: fields[i].label,
          items,
          tags: fields[i].tags,
          type: fields[i].type,
          visible,
          required: fields[i].required,
          loading: false,
          level
        });
      }
      // set level 2
      for (const f in this.form) {
        if ('level' in this.form[f]) {
          if (this.form[f].level == 1) {
            if (this.form[this.form[f].visible[0]].level == 1) {
              this.form[f].level = 2;
            }
          }
        }
      }
    },
    setFormData(vals) {
      for (const item in vals) {
        if (item in this.form && item in vals) {
          this.form[item].value = vals[item];
        }
      }
    },
    resetFormField(f) {
      if (this.form[f].type == 'select') {
        this.form[f].value = { value: null, text: null };
      }
      else {
        this.form[f].value = null;
      }

      this.form[f].error = null;
    },
    createNumberArray(start, end) {
      let arr = [];
      for (let i=start; i<=end; i++) {
        arr.push({ value: i, text: i })
      }
      return arr;
    },
    checkVisible(field_value, field_compare) {
      if (typeof field_value === "string" || typeof field_value === "boolean") {
        if (field_value == field_compare) {
          return true;
        }
      }
      else if (typeof field_value === 'object') {
        if (Array.isArray(field_value)) {
          for (const v in field_value) {
            if (field_value[v].value == field_compare) {
              return true;
            }
          }
        }

        if (field_value) {
          if (field_value.value == field_compare) {
            return true;
          }
        }
      }

      return false;
    },
    resetSign() {
      console.log('Sign reset');
      this.showApp();
      this.hideInfo();
      this.$router.push('/');
    }
  }
}

export default common;