<template>
  <div>
    <div class="inline blue--text">{{ item.document_type_label }}:</div>
    <div class="pb-1" v-for="(item1, index1) in item_value" :key="item1.uuid" :index="index1">
      <div style="word-break: break-all;">
          <a class="link" :href="dLink + '&u=' + item1.uuid" target="_blank">{{ item1.name }}</a>
      </div>
    </div>
    <hr class="grey lighten-5 mb-1" style="opacity: 0.4;">
  </div>
</template>

<script>
export default {
  props: [ 'item', 'item_value', 'hash' ],
  computed: {
    dLink () {
      return this.$appconfig.get_file + '?h=' + this.$route.params.hash
    }
  }
};
</script>


<style scoped>
  .inline { display: inline-block;  }
  .link { color: black; }
</style>