<template>
  <div style="text-align: center;">

    <div :style="imageWidth" class="rounded elevation-6 nbr-image">
      <v-slide-y-transition>
        <img
          class="nbr-logo-img"
          :src="logo"
          v-if="visible"
        />
      </v-slide-y-transition>
    </div>


    <v-app-bar
      class="ma-2"
      app
      color="primary"
      dark
      rounded
      :height="appBarHeight"
    >
      <div align="left" class="nbr-w33 font-weight-regular text-sm-h5 ml-0 ml-sm-4">{{ $appconfig.org_name }}</div>
      <v-spacer/>
      <div align="right" class="nbr-w33 font-weight-regular text-sm-h5 mr-0 mr-sm-4">{{ $appconfig.title }}</div>
    </v-app-bar>
  </div>
</template>

<script>

export default {
  computed: {
    imageWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return { width: '85px' }
        default: return { width: '120px' }
      }
    },
    appBarHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '80px'
        default: return '100px'
      }
    },
    logo() {
      return process.env.VUE_APP_PUBLIC_PATH + 'static/image/' + this.$appconfig.logo;
    }
   },

  mounted() {
    this.visible = true;
  },

  data() {
    return {
      visible: false
    }
  }
};

</script>

<style scoped>
  .nbr-w33 { width: 33%; }
  .nbr-image {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    top: 20px;
    z-index: 1000;
    background: white;
    margin: auto;
    padding: 8px 8px 1px 8px;
  }
  .nbr-logo-img {
    width:  100%;
    height: auto;
  }
</style>