<template>
  <div v-if="isVisible">
    <div class="inline blue--text pr-2">{{ item.label }}{{ colon }} </div>
    <div class="inline" v-html="item_value_c"></div>
    <hr class="grey lighten-5 mb-1" style="opacity: 0.4;">
  </div>
</template>

<script>
export default {
  props: [ 'item', 'item_value' ],
  computed: {
    colon() {
      let c = ':';
      if (this.item.label.indexOf('?') > -1) {
        c = '';
      }
      return c;
    },
    isVisible() {
      if (typeof this.item_value === 'object') {
        if (Array.isArray(this.item_value)) { return true; }
        if (this.item_value || this.item_value == 0) {
          if (this.item_value.text || this.item_value.text == 0) { return true; }
        }
      }
      else {
        if (this.item_value) { return true; }
      }
      return false;
    },
    item_value_c() {
      if (typeof this.item_value === 'object') {
        if (Array.isArray(this.item_value)) {
          if (this.item_value.length == 0) { return 'ništa od navedenog' }
          let _tmp = '';
          for (let i=0; i<this.item_value.length;i++) {
            _tmp += this.item_value[i].text + '; '
          }
          return _tmp.slice(0, -2);
        }
        else {
          return this.item_value.text;
        }
      }
      else if (typeof this.item_value == "boolean") {
        if (this.item_value) { return 'da' }
        else { return 'ne' }
      }
      return this.item_value;
    }
  },
};
</script>

<style scoped>
  .inline { display: inline-block; word-break: break-all; }
</style>