import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import vuetify from './plugins/vuetify';
import router from './router.js';
import store from './store';

//axios.defaults.withCredentials = true;
axios.defaults.headers.get['Accepts'] = 'application/json'

axios.get(process.env.VUE_APP_PUBLIC_PATH + 'config/config.json',)
  .then((res) => {
    Vue.prototype.$appconfig = res.data;
    axios.defaults.baseURL = res.data.api;
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  });
