<template>
  <v-autocomplete
    class="ma-0 pa-0 mt-1 mt-sm-3 mb-0"
    :dense="denseField"
    :outlined="outlined"
    clearable
    label="Tražite mjesto stanovanja ..."
    v-model="city"
    :search-input.sync="search"
    :items="items"
    :error-messages="errorMessages"
    :loading="loading"
    return-object
    @change="handleInput"
  />
</template>
<script>
import axios from 'axios';

export default {
  props: {
    value: { type: Object },
    denseField: { type: Boolean},
    errorMessages: { type: String },
    outlined: { type: Boolean }
  },

  methods: {
    handleInput() {
      this.$emit('input', this.city);
      this.$emit('change');
    }
  },

  watch: {
    search() {
      if (this.city) return;
      this.loading = true;
      axios.post('', { action: 'searchCity', name: this.search })
        .then((res) => {
          this.items = res.data;
        })
        .catch((error) => { console.log(error); })
        .then(() => { this.loading = false; });
    },
    value: { immediate: true, handler(val) {
        if (val) {
          this.items = [ val ];
          this.city = val.value;
        }
        else {
          this.city = null;
        }
      }
    }
  },

  data() {
    return {
      city: null,
      text: null,
      search: null,
      items: [],
      loading: false,
      tab: null,
      city_items: []
    }
  }
};

</script>