import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    overlayCount: 0,
    appVisible: false,
    infoVisible: false,
    infoTitle: '',
    infoText: null,
    infoButton: false,
    dialogVisible: false,
    dialogTitle: null,
    dialogText: null,
    cid: Date.now().toString(36) + Math.random().toString(36).substring(2)
  },
  mutations: {
    mShowOverlay(state) {
      state.overlayCount ++;
    },
    mHideOverlay(state) {
      state.overlayCount --;
    },
    mShowDialog(state, payload) {
      ('app' in payload) ? state.appVisible = payload.app : false;
      state.dialogVisible = true;
      ('title' in payload) ? state.dialogTitle = payload.title : state.dialogTitle = 'GREŠKA';
      state.dialogText = payload.text;
    },
    mHideDialog(state) {
      state.dialogVisible = false;
    },
    mShowInfo(state, payload) {
      ('title' in payload) ? state.infoTitle = payload.title : false;
      ('text' in payload) ? state.infoText = payload.text : false;
      ('button' in payload) ? state.infoButton = payload.button : false;
      state.appVisible = false;
      state.infoVisible = true;
    },
    mHideInfo(state) {
      state.infoVisible = false;
      state.appVisible = true;
    },
    mShowApp(state) {
      state.appVisible = true;
    },
    mHideApp(state) {
      state.appVisible = false;
    }
  },
  actions: {
    showOverlay(context) {
      context.commit('mShowOverlay');
    },
    hideOverlay(context) {
      context.commit('mHideOverlay');
    },
    showDialog(context, payload) {
      context.commit('mShowDialog', payload);
    },
    hideDialog(context) {
      context.commit('mHideDialog');
    },
    showInfo(context, payload) {
      context.commit('mShowInfo', payload);
    },
    hideInfo(context) {
      context.commit('mHideInfo');
    },
    showApp(context) {
      context.commit('mShowApp');
    },
    hideApp(context) {
      context.commit('mHideApp');
    }
  },
  getters: {
    overlayVisible: (state) => {
      if (state.overlayCount <= 0) {
        return false;
      }
      else {
        return true;
      }
    }
  }
})