<template>
  <v-container v-show="showForm">
    <div class="mb-10">
      <the-page-title
        title="DOKUMENTI ZA DODATNE BODOVE"
        :steps="steps"
      />
      <the-child-info
        :name="child_name"
        :surname="child_surname"
        :pin="child_pin"
        :email="email"
      />
    </div>

    <div class="" v-if="r_document_list">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">OBAVEZNI DOKUMENTI</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-0 mb-3 elevation-6 rounded fluid nbr-mw800">
            <file-upload
              v-for="(item, index) in r_document_list" :key="item.document_type_code" :index="index"
              :dense="denseField"
              :hash="form.hash.value"
              :max="item.max_files"
              :label="item.document_type_label"
              :note="item.document_note"
              :error-messages="form[item.document_type_code].error"
              @error="showError"
              v-model="form[item.document_type_code].value"
              @change="form[item.document_type_code].error=''"
            />
          </div>
        </v-row>
    </div>

    <div class="mt-10 mb-10">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">DOKUMENTI</div>
        </div>
      </v-row>

    <v-row class="justify-center">
      <div class="pa-6 pb-0 elevation-6 rounded fluid nbr-mw800">
          <v-alert
            class="mb-6"
            border="top"
            color="yellow lighten-2"
          >
            <b>NAPOMENA:</b><br>
            Priloženim dokumentima ostvarujete dodatne bodove prilikom upisa.<br>
            Ovisno o podatcima u prethodnim koracima neki dodatni dokumenti mogu biti obavezni.
          </v-alert>
          <file-upload
            v-for="(item, index) in document_list" :key="item.document_type_code" :index="index"
            :dense="denseField"
            :hash="form.hash.value"
            :max="item.max_files"
            :label="item.document_type_label"
            :note="item.document_note"
            :error-messages="form[item.document_type_code].error"
            @error="showError"
            v-model="form[item.document_type_code].value"
            @change="form[item.document_type_code].error=''"
          />
        </div>
      </v-row>
    </div>

    <v-row class="justify-center mb-4">
      <v-card class="pa-2 elevation-6 blue nbr-mw800">
        <v-card-actions>
          <v-btn
            @click="postForm(prevRoute)"
          >Nazad</v-btn>
          <v-spacer/>
          <v-btn
            @click="postForm('provjera-i-potvrda')"
          >Spremi dokumente</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

  </v-container>
</template>

<script>
import ThePageTitle from '../components/ThePageTitle';
import TheChildInfo from '../components/TheChildInfo';
import FileUpload from '../components/FileUpload';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'Document',
  mixins: [ common ],
  mounted () {
    this.getFormData();
    console.log('Hash: ' + this.form.hash.value);
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    }
  },

  methods: {
    getProgramItems(oid, uel) {
      this.$data[uel + '_loading'] = true;
      axios.post('', { action: 'getProgramByOrg', org_id: oid })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.$data[uel + '_items'] = res.data.program_items;
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => {
          this.hideOverlay();
          this.$data[uel + '_loading'] = false;
        });
    },
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormDocument', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            //
            this.child_name = res.data.form_basic.child_name;
            this.child_surname = res.data.form_basic.child_surname;
            this.child_pin = res.data.form_basic.child_pin;
            this.email = res.data.form_basic.email;
            this.r_document_list = res.data.r_document_list;
            this.document_list = res.data.document_list;
            this.steps = res.data.steps;
            this.p89 = eval(res.data.p89);
            if (this.p89) { this.prevRoute = 'upitnik' }

            // kreiraj dinamički objekte u data.form
            for (const item in res.data.r_document_list) {
              this.$set(this.form, item, { value: null, error: null });
            }
            for (const item in res.data.document_list) {
              this.$set(this.form, item, { value: null, error: null });
            }
            // set values
            for (const item in res.data.form_document) {
              if (item in this.form && item in res.data.form_document) {
                this.form[item].value = res.data.form_document[item];
              }
            }
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => {
          this.hideOverlay();
          this.showForm = true;
        });
    },
    postForm(route) {
      this.showOverlay();
      axios.post('', { action: 'saveFormDocument', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            console.log('Route change to: ' + 'prijava/' + this.form.hash.value + '/' + route);
            this.$router.push('/prijava/' + this.form.hash.value + '/' + route);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    },
    showError(m) {
      this.showDialog({ text: m, app: true });
    },
  },

  watch: {
    'form.org1.value' (n, o) {
      if (this.init) return;
      if (n != o) {
        this.getProgramItems(n, 'program1');
        this.form.program1.value = null;
      }
    },
    'form.org2.value' (n, o) {
      if (this.init) return;
      if (n != o) {
        this.getProgramItems(n, 'program2');
        this.form.program2.value = null;
      }
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash}
      },
      //
      child_name: null,
      child_surname: null,
      child_pin: null,
      email: null,
      showForm: false,
      r_document_list: null,
      document_list: null,
      p89: null,
      prevRoute: 'c',
      steps: null
    }
  },

  components: {
    TheChildInfo,
    ThePageTitle,
    FileUpload
  }
};

</script>