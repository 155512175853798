<template>
  <v-container v-show="showForm">
    <the-page-title
      title="DETALJI ZAHTJEVA ZA UPIS"
    />
    <the-child-info
      :name="child_name"
      :surname="child_surname"
      :pin="child_pin"
      :email="email"
    />

    <review-component />
 </v-container>
</template>

<script>
import ReviewComponent from '../components/ReviewComponent';
import ThePageTitle from '../components/ThePageTitle';
import TheChildInfo from '../components/TheChildInfo';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'Review',
  mixins: [ common ],
  created () {
    this.getFormData();
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    }
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormSignView', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.child_name = res.data.form_basic.child_name;
            this.child_surname = res.data.form_basic.child_surname;
            this.child_pin = res.data.form_basic.child_pin;
            this.email = res.data.form_basic.email;
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => {
          this.hideOverlay();
          this.showForm = true;
          this.init = false;
        });
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash },
        confirm: { value: false, error: '' }
      },
      showForm: false,
      child_name: null,
      child_surname: null,
      child_pin: null,
      email: null
    }
  },

  components: {
    ReviewComponent,
    TheChildInfo,
    ThePageTitle
  }
};

</script>
