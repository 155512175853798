<template>
  <v-scale-transition>
  <v-container v-if="visible">
    <v-row class="justify-center mt-2 mt-sm-8 ml-2 mr-2">
      <div class="mt-3 mt-sm-8 mb-3 mb-sm-8 elevation-6 rounded fluid nbr-mw800">
        <div class="ma-2 pa-2 text-sm-h5">{{ title }}</div>
        <v-alert
            class="ma-3"
            border="top"
            color="yellow lighten-2"
          >
            <div class="pt-2 text-body-2 text-sm-body-1" v-html="text"></div>
          </v-alert>
          <div v-if="button" style="width: 100%; text-align: right;">
            <v-btn class="mb-4 mt-2 mr-6" @click="$emit('resetSign')">Nova prijava</v-btn>
          </div>
        </div>
    </v-row>
  </v-container>
  </v-scale-transition>
</template>

<script>

export default {
  props: [ 'title', 'text', 'button' ],

  mounted() {
    this.visible = true;
  },

  data() {
    return {
      visible: false
    }
  }
};

</script>