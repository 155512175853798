<template>
  <v-container v-show="showForm">
    <div class="mb-10">
      <the-page-title
        title="ODABERITE VRTIĆ"
        :steps="steps"
      />
      <the-child-info
        :name="child_name"
        :surname="child_surname"
        :pin="child_pin"
        :email="email"
      />
    </div>

    <div v-if="is_oorg" class="mb-10">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">VRTIĆKA KUĆA</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g0' && index=='oorg'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              :loading="form[index].loading"
              @change="form[index].error=''"
              return-object
            />
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g0' && index=='oorg_external' && show_org_external"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              :loading="form[index].loading"
              @change="form[index].error=''"
              return-object
            />
          </div>
        </div>
      </v-row>
    </div>

    <v-scale-transition>
    <div v-if="(oorg_has_value && no_programs > 0) || !is_oorg" class="" >
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">PRIMARNI VRTIĆ</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g1'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              :loading="form[index].loading"
              @change="form[index].error=''"
              return-object
            />
          </div>
        </div>
      </v-row>
    </div>
    </v-scale-transition>

    <v-scale-transition>
    <div v-if="(oorg_has_value && no_programs > 1) || !is_oorg" class="mt-10">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">{{ alt1 }}</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g2'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              :loading="form[index].loading"
              @change="form[index].error=''"
              return-object
            />
          </div>
        </div>
      </v-row>
    </div>
    </v-scale-transition>

    <v-scale-transition>
    <div v-if="(alt2)">
      <div v-if="(oorg_has_value && no_programs > 1) || !is_oorg" class="mt-10">
        <v-row class="justify-center">
          <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
            <div class="text-center ma-2 text-sm-h6">{{ alt2 }}</div>
          </div>
        </v-row>
        <v-row class="justify-center">
          <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
            <div v-for="(item, index) in form" :key="item.name" :index="index">
              <v-select
                class="mb-3 mb-sm-0"
                v-if="item.type=='select' && item.tags=='g3'"
                :dense="denseField"
                :outlined="outlined"
                :label="item.label"
                :items="item.items"
                v-model="form[index].value"
                :error-messages="form[index].error"
                :loading="form[index].loading"
                @change="form[index].error=''"
                return-object
              />
            </div>
          </div>
        </v-row>
      </div>
    </div>
    </v-scale-transition>

    <v-scale-transition>
    <div v-if="p91" class="mt-10">
      <v-row class="justify-center">
        <div class="pa-6 pt-1 pb-1 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-checkbox
              class="mb-3 mb-sm-0"
              v-if="item.type=='checkbox' && item.tags=='g4'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              :loading="form[index].loading"
              @change="form[index].error=''"
              return-object
            />
          </div>
        </div>
      </v-row>
    </div>
    </v-scale-transition>

    <div class="mb-10"></div>

    <v-row class="justify-center mb-4">
      <v-card class="pa-2 elevation-6 blue  nbr-mw800">
        <v-card-actions>
          <v-btn
            @click="postForm('b')"
          >Nazad</v-btn>
          <v-spacer/>
          <v-spacer></v-spacer>
          <v-btn
            @click="postForm(nextRoute)"
          >Spremi podatke o vrtićima</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ThePageTitle from '../components/ThePageTitle';
import TheChildInfo from '../components/TheChildInfo';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'SignC',
  mixins: [ common ],
  mounted () {
    this.getFormData();
    console.log('Hash: ' + this.form.hash.value);
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    },
    alt1 () {
      if (this.p201 > 2) {
        return 'ZAMJENSKI VRTIĆ 1';
      }
      else {
        return 'ZAMJENSKI VRTIĆ';
      }
    },
    alt2 () {
      if (this.p201 > 2) {
        return 'ZAMJENSKI VRTIĆ 2';
      }
      else {
        return false;
      }
    }
  },

  methods: {
    getOrgItems(oorg_id) {
      this.$data.form['org1'].loading = true;
      this.$data.form['org2'].loading = true;
      axios.post('', { action: 'getOrgItems', oorg_id: oorg_id, hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.$data.form['org1'].items = res.data.org_items;
            this.$data.form['org2'].items = res.data.org_items;
            if (this.p201 > 2) {
              this.$data.form['org3'].items = res.data.org_items;
            }
            this.no_programs = res.data.no_programs;
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => {
          this.$data.form['org1'].loading = false;
          this.$data.form['org2'].loading = false;
          if (this.p201 > 2) {
            this.$data.form['org3'].loading = false;
          }
        });
    },
    getProgramItems(oid, uel) {
      this.$data.form[uel].loading = true;
      axios.post('', { action: 'getProgramByOrg', org_id: oid, hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.$data.form[uel].items = res.data.program_items;
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => {
          this.$data.form[uel].loading = false;
        });
    },
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormC', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.child_name = res.data.form_basic.child_name;
            this.child_surname = res.data.form_basic.child_surname;
            this.child_pin = res.data.form_basic.child_pin;
            this.email = res.data.form_basic.email;
            this.is_oorg = res.data.is_oorg;
            this.p89 = eval(res.data.p89);
            this.p91 = eval(res.data.p91);
            this.p201 = eval(res.data.p201);
            if (this.p89) { this.nextRoute = 'upitnik' }

            this.setFormFields(res.data.fields);

            this.form.oorg.items = res.data.oorgs;
            this.form.oorg_external.items = res.data.oorgs_external;
            this.form.org1.items = res.data.org_items;
            this.form.org2.items = res.data.org_items;
            this.form.program1.items = res.data.program1_items;
            this.form.program2.items = res.data.program2_items;
            if (this.p201 > 2) {
              this.form.org3.items = res.data.org_items;
              this.form.program3.items = res.data.program3_items;
            }
            this.no_programs = res.data.no_programs;
            this.steps = res.data.steps;

            this.setFormData(res.data.form_c);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => {
          this.hideOverlay();
          this.showForm = true;
          this.init = false;
        });
    },
    postForm(route) {
      this.showOverlay();
      axios.post('', { action: 'saveFormC', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            console.log('Route change to: ' + 'prijava/' + this.form.hash.value + '/' + route);
            this.$router.push('/prijava/' + this.form.hash.value + '/' + route);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    },
    orgValueChanged(n) {
      this.form.org1.value = { value: null, text: null };
      this.form.org2.value = { value: null, text: null };
      this.form.program1.value = { value: null, text: null };
      this.form.program2.value = { value: null, text: null };
      this.form.program1.items = null;
      this.form.program2.items = null;
      this.form.org1.items = null;
      this.form.org2.items = null;
      this.oorg_has_value = true;
      if (this.p201 > 2) {
        this.form.org3.value = { value: null, text: null };
        this.form.program3.value = { value: null, text: null };
        this.form.program3.items = null;
        this.form.org3.items = null;
      }
      this.getOrgItems(n);
    }
  },

  watch: {
    'form.oorg.value.value' (n) {
      if (n) {
        this.show_org_external = false;
        if (this.init) {
          this.oorg_has_value = true;
          if (n == -1) {
            this.show_org_external = true;
          }
          return;
        }
        if (n == -1) {
          this.show_org_external = true;
        }
        this.form.oorg_external.value = { value: null, text: null };
        this.orgValueChanged(n);
      }
    },
    'form.oorg_external.value.value' (n) {
      if (n) {
        if (this.init) {
          this.oorg_has_value = true;
          return;
        }
        this.orgValueChanged(n);
      }
    },
    'form.org1.value.value' (n, o) {
      if (this.init) return;
      if (n != o) {
        if (n) {
          this.getProgramItems(n, 'program1');
        }
        this.form.program1.value.value = null;
      }
    },
    'form.org2.value.value' (n, o) {
      if (this.init) return;
      if (n != o) {
        if (n) {
          this.getProgramItems(n, 'program2');
        }
        this.form.program2.value.value = null;
      }
    },
    'form.org3.value.value' (n, o) {
      if (this.init) return;
      if (this.p201 < 3) return;
      if (n != o) {
        if (n) {
          this.getProgramItems(n, 'program3');
        }
        this.form.program3.value.value = null;
      }
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash},
        // dummy variable to handle null visible_condition field
        _at: { value: true }
      },
      //
      child_name: null,
      child_surname: null,
      child_pin: null,
      email: null,
      showForm: false,
      outlined: false,
      init: true,
      steps: null,
      is_oorg: null,
      oorg_has_value: null,
      no_programs: null,
      show_org_external: false,
      p89: false,
      p91: false,
      p201: false,
      nextRoute: 'dokumenti'
    }
  },

  components: {
    TheChildInfo,
    ThePageTitle
  }
};

</script>