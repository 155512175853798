<template>
  <v-row class="justify-center mt-2 mt-sm-8">
    <div class="blue-grey lighten-5 mt-2 mt-sm-6 mb-2 mb-sm-4 elevation-6 rounded fluid nbr-mw800">
      <div class="text-center ma-2 pa-1 mb-0 pb-0 text-sm-h5">{{ title }}</div>
      <div v-if="steps">
        <div class="text-center mb-2 text-caption">(korak <b>{{ steps[0] }}</b> od <b>{{ steps[1] }}</b>)</div>
      </div>
      <div v-else>
        <div class="text-center mb-2 pb-1 text-caption"></div>
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  props: [ 'title', 'steps' ],
};
</script>