<template>
  <div :class="margin">
    <v-card outlined class="pa-3 pb-0">
      <v-row no-gutters>
        <v-col class="pr-2">
          <div class="text-body-2">{{ label }}</div>
        </v-col>
        <v-col v-if="note" class="ml-2 mr-2" cols="1">
          <v-btn
            elevation="2"
            fab
            @click="showM(note)"
          >
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row >
        <v-col align="">
        <v-btn
          v-if="isVisible"
          :disabled="isDisabled"
          color="blue-grey"
          class="mb-4 white--text"
          @click="handleButton"
        >
          Dodaj datoteku
          <v-icon
            right
            dark
          >
            mdi-cloud-upload
          </v-icon>
        </v-btn>
        <v-file-input
          ref="uploader"
          class="d-none"
          outlined
          accept="application/pdf"
          v-model="file"
          @change="uploadFile"
        />

        <v-progress-linear
          class="mb-4 white--text"
          color="blue-grey"
          v-if="!isVisible"
          v-model="uploadPercentage"
          height="36"
          rounded
        >
          {{ file.name }}
        </v-progress-linear>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <div class="pa-3 mb-4 elevation-1 rounded" style="width: 100%">
          <div v-if="files.length" class="text-left">
            <v-chip
              style="height: auto; white-space: normal; word-break: break-all;"
              class="ma-2 text-body-1"
              color="primary"
              outlined
              v-for="(item, index) in files" :key="item.id" :index="index"
              label
              close
              close-icon="mdi-close-outline"
              @click:close="files.splice(index, 1)"
            >
              <div style="padding-bottom: 3px; padding-top: 4px;">{{ item.name  }}</div>
            </v-chip>
          </div>
          <div v-if="!files.length && !errorMessages" class="ma-3 grey--text">Nema dodanih datoteka</div>
          <div v-if="errorMessages" class="ma-3 red--text">{{ errorMessages }}</div>
        </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios';
import common from '../common.js';

export default {
  props: [ 'value', 'dense', 'label', 'max', 'hash', 'errorMessages', 'note' ],
  mixins: [ common ],

  methods: {
    uploadFile() {
      if (!this.file) return;

      if (this.file.size > 1024*1024*this.$appconfig.max_file_size) {
        let fsize = (Math.round(this.file.size/1024/1024*100)/100).toString().replace('.', ',') + ' MB';
        this.$emit('error', 'Datoteka je velika ' + fsize + '. Dozvoljena veličina je 10 MB.');
        return;
      }

      if (this.files.length > 0) {
        for (let i=0; i<this.files.length; i++) {
          if (this.file.name == this.files[i].name &&
              this.file.size == this.files[i].size &&
              this.file.type == this.files[i].type &&
              this.file.lastModified == this.files[i].lastModified )
          {
            console.log('File already uploaded: ' + this.file.name);
            this.$emit('error', 'Datoteka je već dodana.');
            return;
          }
        }
      }

      let fd = new FormData();
      fd.append('file', this.file);
      fd.append('hash', this.hash);

      const config = {
        onUploadProgress: progressEvent => this.updateProgress(progressEvent),
        headers: { 'Content-Type': 'multipart/form-data' }
      };

      axios.post(this.$appconfig.dropzone, fd, config)
        .then((res) => {
          if (res.data.status == 0) {
            let _file = {};
            _file.name = this.file.name;
            _file.size = this.file.size;
            _file.type = this.file.type;
            _file.lastModified = this.file.lastModified;
            _file.uuid = res.data.uuid;
            this.files.push(_file);
          }
          else {
            this.$emit('error', res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$emit('error', error);
        })
        .then(() => {});
    },
    updateProgress(e) {
      this.isVisible = false;
      this.uploadPercentage = parseInt(Math.round((e.loaded/e.total )*100));
      if (this.uploadPercentage == 100) {
        this.uploadPercentage = 0;
        this.isVisible = true;
      }
    },
    handleButton() {
      this.isSelecting = true;
      //window.addEventListener('focus', () => { this.isSelecting = false }, { once: true });
      this.$refs.uploader.$refs.input.click()
    },
    showM(m) {
      this.showDialog({ title: "Objašnjenje", text: m, app: true });
    }
  },

  watch: {
    dense(val) {
      if(val) {
        this.margin = 'mb-6';
      }
    },
    files(val) {
      if (val.length >= this.max) {
        this.isDisabled = true;
      }
      else {
        this.isDisabled = false;
      }
      this.file = null;
      this.$emit('input', val);
      this.$emit('change');
    },
    value: { immediate: true, handler(val) {
        if (val) {
          this.files = this.value;
        }
        else {
          this.files = [];
        }
      }
    }
  },

  data() {
    return {
      file: null,
      files: [],
      isSelecting: false,
      isVisible: true,
      isDisabled: false,
      uploadPercentage: 0,
      margin: 'mb-4 mb-sm-6'
    }
  }
};

</script>