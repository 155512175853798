<template>
  <v-scale-transition>
  <v-container v-show="showForm">
    <the-page-title
      :title="$appconfig.basic_form_title"
    />

    <v-row class="justify-center">
      <div class="pa-6 pt-8 pt-sm-4 pb-4 mb-3 mb-sm-8 elevation-6 rounded fluid nbr-mw800">
        <div v-for="(item, index) in form" :key="item.name" :index="index">
          <v-text-field
            class="mb-3 mb-sm-0"
            v-if="item.type=='text' && item.tags=='g1'"
            :dense="denseField"
            :outlined="outlined"
            :label="item.label"
            v-model="form[index].value"
            :error-messages="form[index].error"
            @keypress.native="form[index].error=''"
          />
        </div>
      </div>
    </v-row>
    <v-row class="justify-center">
      <div class="pa-6 pt-8 pt-sm-4 pb-4 mb-3 mb-sm-8 elevation-6 rounded fluid nbr-mw800">
        <div v-for="(item, index) in form" :key="item.name" :index="index">
          <v-text-field
            class="mb-3 mb-sm-0"
            v-if="item.type=='text' && item.tags=='g2'"
            :dense="denseField"
            :outlined="outlined"
            :label="item.label"
            v-model="form[index].value"
            :error-messages="form[index].error"
            @keypress.native="form[index].error=''"
          />
        </div>
      </div>
    </v-row>
    <v-row class="justify-center">
      <div class="pa-6 pt-2 pb-4 mb-3 elevation-6 rounded fluid nbr-mw800">
        <div class="text-center ma-6 mb-0">
          <a @click="refreshCaptcha">
            <img id='siimage' :src="captcha_image" alt='CAPTCHA'>
          </a>
          <div class="text-caption text-center">(klinki na sliku za novi kod)</div>
        </div>

        <v-text-field
          class="mb-3 mb-sm-0"
          :dense="denseField"
          :outlined="outlined"
          label="Sigurnosni kod"
          v-model="form.captcha_code.value"
          :error-messages="form.captcha_code.error"
          @keypress.native="form.captcha_code.error=''"
        />
      </div>
    </v-row>
    <v-row class="justify-center">
      <div class="pa-6 pt-8 pt-sm-4 pb-4 mb-3 mb-sm-8 elevation-6 rounded fluid nbr-mw800">
        <div v-for="(item, index) in form" :key="item.name" :index="index">
          <v-checkbox
            class="mb-3 mb-sm-0"
            v-if="item.type=='checkbox' && item.tags=='g3'"
            :dense="denseField"
            v-model="form[index].value"
            :error-messages="form[index].error"
            @change="form[index].error=''"
            :label="item.label"
          />
        </div>
      </div>
    </v-row>

    <v-row class="justify-center mb-4">
      <v-card class="pa-2 elevation-6 blue nbr-mw800">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="postForm('b')"
          >Spremi podatke o djetetu</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</v-scale-transition>
</template>

<script>
import ThePageTitle from '../components/ThePageTitle'
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'Home',
  mixins: [ common ],
  mounted () {
    this.checkSignStatus();
    this.refreshCaptcha();
    this.getFormData();
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    },
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormBasic' })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.setFormFields(res.data.fields);
            for (const item in res.data.document_list) {
              this.$set(this.form, item, { value: null, error: null });
            }
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => { this.hideOverlay(); this.showForm = true; });
    },
    checkSignStatus() {
      this.showOverlay();
      axios.post('', { action: 'checkSignStatus' })
        .then((res) => { this.cmnAxiosPost(res); })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => { this.hideOverlay(); this.showForm = true; });
    },
    refreshCaptcha() {
      this.captcha_image = this.$appconfig.captcha
        + '?t=' + new Date().getTime()
        + '&cid=' + this.$store.state.cid;
    },
    postForm() {
      this.showOverlay();
      axios.post('', { action: 'saveFormBasic', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.showInfo({ title: 'OBAVIJEST', text: res.data.message, button: false });
          }
          else {
            this.refreshCaptcha();
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    }
  },

  data() {
    return {
      form: {
        captcha_code: { value: null, error: '' },
        captcha_cid: { value: this.$store.state.cid, error: '' }
      },
      //
      captcha_image: null,
      capctha_id: (Math.random() + 1).toString(36).substring(7),
      outlined: false,
      showForm: false
    }
  },

  components: {
    ThePageTitle
  }
};

</script>