<template>
  <v-container v-show="showForm">
    <div class="mb-10">
      <the-page-title
        title="OBITELJSKI PODATCI"
        :steps="steps"
      />
      <the-child-info
        :name="child_name"
        :surname="child_surname"
        :pin="child_pin"
        :email="email"
      />
    </div>

    <div v-if="[110,111,120,130,121].includes(child_family_status.value)" class="">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
        <div class="text-center ma-2 text-sm-h6">PODATCI O MAJCI/SKRBNICI</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pt-8 pt-sm-4 pb-0 elevation-6 rounded fluid nbr-mw800">
          <div style="width: 100%; text-align: right;">
            <v-btn
              class="mb-3"
              @click="copyChildAddress('mother')"
            >
              Kopiraj adresu od djeteta
              <v-icon
                right
                dark
              >
                mdi-content-copy
              </v-icon>
            </v-btn>
          </div>
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g1'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              return-object
            />
            <v-text-field
              class="mb-3 mb-sm-0"
              v-if="item.type=='text' && item.tags=='g1'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @keypress.native="form[index].error=''"
            />
            <city-search
              class="mb-3 mb-sm-0"
              v-if="index=='mother_city_n' && form[item.visible[0]].value.value == item.visible[1]"
              :dense="denseField"
              :outlined="outlined"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
            />
          </div>
          <div v-for="(item, index) in document_list_m" :key="item.document_type_code + 'm1'" :index="index">
            <v-scale-transition>
            <file-upload
              class="mb-3 mb-sm-0"
              v-if="(index=='mother_D117' || index=='mother_D118') && form[index].visible"
              :dense="denseField"
              :hash="form.hash.value"
              :max="item.max_files"
              :label="item.document_type_label"
              :note="item.document_note"
              :error-messages="form[index].error"
              @error="showError"
              v-model="form[index].value"
              @change="form[index].error=''"
            />
            </v-scale-transition>
          </div>
          <div v-if="p94">
            <div v-for="(item, index) in form" :key="item.name" :index="index">
              <v-checkbox
                class="mb-3 mb-sm-0"
                v-if="item.type=='checkbox' && item.tags=='g11'"
                :dense="denseField"
                v-model="form[index].value"
                :error-messages="form[index].error"
                @change="form[index].error=''"
                :label="item.label"
              />
            </div>
            <div v-for="(item, index) in document_list_m" :key="item.document_type_code + 'm2'" :index="index">
              <v-scale-transition>
              <file-upload
                class="mb-3 mb-sm-0"
                v-if="index=='mother_D119' && form[index].visible"
                :dense="denseField"
                :hash="form.hash.value"
                :max="item.max_files"
                :label="item.document_type_label"
                :note="item.document_note"
                :error-messages="form[index].error"
                @error="showError"
                v-model="form[index].value"
                @change="form[index].error=''"
              />
              </v-scale-transition>
            </div>
          </div>
          <div class="mt-3"></div>
          <div v-for="(item, index) in document_list_m" :key="item.document_type_code + 'm3'" :index="index">
            <file-upload
              class="mb-3 mb-sm-0"
              v-if="index=='mother_D103'"
              :dense="denseField"
              :hash="form.hash.value"
              :max="item.max_files"
              :label="item.document_type_label"
              :note="item.document_note"
              :error-messages="form[index].error"
              @error="showError"
              v-model="form[index].value"
              @change="form[index].error=''"
            />
          </div>
        </div>
      </v-row>
    </div>

    <div v-if="[110,111,120,130,131].includes(child_family_status.value)" class="mt-10">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">PODATCI O OCU/SKRBNIKU</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pt-8 pt-sm-4 pb-0 elevation-6 rounded fluid nbr-mw800">
          <div style="width: 100%; text-align: right;">
            <v-btn
              class="mb-3"
              @click="copyChildAddress('father')"
            >
              Kopiraj adresu od djeteta
              <v-icon
                right
                dark
              >
                mdi-content-copy
              </v-icon>
            </v-btn>
          </div>
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g2'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              return-object
            />
            <v-text-field
              class="mb-3 mb-sm-0"
              v-if="item.type=='text' && item.tags=='g2'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @keypress.native="form[index].error=''"
            />
            <city-search
              class="mb-3 mb-sm-0"
              v-if="index=='father_city_n' && form[item.visible[0]].value.value == item.visible[1]"
              :dense="denseField"
              :outlined="outlined"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
            />
          </div>
          <div v-for="(item, index) in document_list_f" :key="item.document_type_code + 'f1'" :index="index">
            <v-scale-transition>
            <file-upload
              class="mb-3 mb-sm-0"
              v-if="(index=='father_D117' || index=='father_D118') && form[index].visible"
              :dense="denseField"
              :hash="form.hash.value"
              :max="item.max_files"
              :label="item.document_type_label"
              :note="item.document_note"
              :error-messages="form[index].error"
              @error="showError"
              v-model="form[index].value"
              @change="form[index].error=''"
            />
            </v-scale-transition>
          </div>
          <div v-if="p94">
            <div v-for="(item, index) in form" :key="item.name" :index="index">
              <v-checkbox
                class="mb-3 mb-sm-0"
                v-if="item.type=='checkbox' && item.tags=='g21'"
                :dense="denseField"
                v-model="form[index].value"
                :error-messages="form[index].error"
                @change="form[index].error=''"
                :label="item.label"
              />
            </div>
            <div v-for="(item, index) in document_list_f" :key="item.document_type_code + 'f2'" :index="index">
              <v-scale-transition>
              <file-upload
                class="mb-3 mb-sm-0"
                v-if="index=='father_D119' && form[index].visible"
                :dense="denseField"
                :hash="form.hash.value"
                :max="item.max_files"
                :label="item.document_type_label"
                :note="item.document_note"
                :error-messages="form[index].error"
                @error="showError"
                v-model="form[index].value"
                @change="form[index].error=''"
              />
              </v-scale-transition>
            </div>
          </div>
          <div class="mt-3"></div>
          <div v-for="(item, index) in document_list_f" :key="item.document_type_code + 'f3'" :index="index">
            <file-upload
              class="mb-3 mb-sm-0"
              v-if="index=='father_D103'"
              :dense="denseField"
              :hash="form.hash.value"
              :max="item.max_files"
              :label="item.document_type_label"
              :note="item.document_note"
              :error-messages="form[index].error"
              @error="showError"
              v-model="form[index].value"
              @change="form[index].error=''"
            />
          </div>
        </div>
      </v-row>
    </div>

    <div class="mb-10 mt-10">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">OSNOVNI PODATCI O OBITELJI</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g32'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              return-object
            />
          </div>
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g33'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              return-object
            />
          </div>
          <div v-for="(item, index) in document_list_o" :key="item.document_type_code + 'o3'" :index="index">
            <v-scale-transition>
            <file-upload
              class="mb-3 mb-sm-0"
              v-if="index=='family_D112' && form[index].visible"
              :dense="denseField"
              :hash="form.hash.value"
              :max="item.max_files"
              :label="item.document_type_label"
              :note="item.document_note"
              :error-messages="form[index].error"
              @error="showError"
              v-model="form[index].value"
              @change="form[index].error=''"
            />
            </v-scale-transition>
          </div>
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              class="mb-3 mb-sm-0"
              v-if="item.type=='select' && item.tags=='g34'"
              :dense="denseField"
              :outlined="outlined"
              :label="item.label"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              return-object
            />
          </div>

          <div v-if="p57 && siblingVisible">
            <v-alert
              class="mb-6"
              border="top"
              color="yellow lighten-2"
            >
              <b>NAPOMENA:</b><br>
              Ukoliko imate više djece koja već pohađaju vrtić upišite podatke jednog od njih.
            </v-alert>

            <div v-for="(item, index) in form" :key="item.name" :index="index">
              <v-text-field
                class="mb-3 mb-sm-0"
                v-if="item.type=='text' && item.tags=='g35'"
                :dense="denseField"
                :outlined="outlined"
                :label="item.label"
                v-model="form[index].value"
                :error-messages="form[index].error"
                @keypress.native="form[index].error=''"
                />
            </div>
          </div>

        </div>
      </v-row>
    </div>

    <v-row class="justify-center mb-4">
      <v-card class="pa-2 elevation-6 blue nbr-mw800">
        <v-card-actions>
          <v-btn
            @click="postForm('a')"
          >Nazad</v-btn>
          <v-spacer/>
          <v-btn
            @click="postForm('c')"
          >Spremi obiteljske podatke</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ThePageTitle from '../components/ThePageTitle'
import FileUpload from '../components/FileUpload';
import TheChildInfo from '../components/TheChildInfo';
import CitySearch from '../components/CitySearch';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'SignB',
  mixins: [ common ],
  mounted () {
    this.getFormData();
    console.log('Hash: ' + this.form.hash.value);
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    }
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormB', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.form_a = res.data.form_a;
            this.child_name = res.data.form_basic.child_name;
            this.child_surname = res.data.form_basic.child_surname;
            this.child_pin = res.data.form_basic.child_pin;
            this.email = res.data.form_basic.email;
            this.document_list_m = res.data.document_list_m;
            this.document_list_f = res.data.document_list_f;
            this.document_list_o = res.data.document_list_o;
            this.child_family_status = res.data.child_family_status;
            this.p57 = eval(res.data.p57);
            this.p60 = res.data.p60;
            this.p94 = eval(res.data.p94);
            this.steps = res.data.steps;
            //
            this.setFormFields(res.data.fields);

            for (const item in res.data.document_list_m) {
              this.$set(this.form, item, { value: null, error: null });
            }
            for (const item in res.data.document_list_f) {
              this.$set(this.form, item, { value: null, error: null });
            }
            for (const item in res.data.document_list_o) {
              this.$set(this.form, item, { value: null, error: null });
            }

            this.form.mother_city.items = res.data.city_items;
            this.form.father_city.items = res.data.city_items;
            this.form.family_child_no.items = this.createNumberArray(1,20);
            this.form.family_child_no_in_org.items = this.createNumberArray(0,20);
            this.form.mother_job_status.items = res.data.job_status_items;
            this.form.father_job_status.items = res.data.job_status_items;

            this.setFormData(res.data.form_b);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => {
          this.hideOverlay();
          this.showForm = true;
          this.init = false;
        });
    },
    postForm(route) {
      this.showOverlay();
      axios.post('', { action: 'saveFormB', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            console.log('Route change to: ' + 'prijava/' + this.form.hash.value + '/' + route);
            this.$router.push('/prijava/' + this.form.hash.value + '/' + route);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    },
    showError(m) {
      this.showDialog({ text: m, app: true });
    },
    copyChildAddress(w) {
      this.form[w + '_street'].value = this.form_a.child_street;
      this.form[w + '_street'].error='';
      this.form[w + '_street_no'].value = this.form_a.child_street_no;
      this.form[w + '_street_no'].error='';
      this.form[w + '_street_subno'].value = this.form_a.child_street_subno;
      this.form[w + '_street_subno'].error='';
      this.form[w + '_street_subno'].value = this.form_a.child_street_subno;
      this.form[w + '_street_subno'].error='';
      this.form[w + '_city'].value = this.form_a.child_city;
      this.form[w + '_city'].error='';
      this.form[w + '_city_n'].value = this.form_a.child_city_n;
      this.form[w + '_city_n'].error='';
    }
  },

  watch: {
    'form.family_child_no_in_org.value.value': { immediate: true, handler(n) {
        if (n > 0) {
          this.siblingVisible = true
        }
        else {
         this.siblingVisible = false
        }

        if (n == 0) {
          this.form.sibling_firstname.value = null;
          this.form.sibling_surname.value = null;
          this.form.sibling_pin.value = null;
        }
      },
    },
    'form.mother_city.value.value' (_, o) {
      if (o == -1) { this.form.mother_city_n.value = null }
    },
    'form.father_city.value.value' (_, o) {
      if (o == -1) { this.form.father_city_n.value = null }
    },
    'form.mother_job_status.value': { immediate: true, handler(n) {
        if (typeof n !== 'undefined') {
          if (n.text) {
            this.form['mother_D117'].visible = false;
            this.form['mother_D118'].visible = false;
            if (!this.init) {
              this.form['mother_D117'].value = null;
              this.form['mother_D118'].value = null;
            }
            if (n.text.search('odnosu') > -1 || n.text.search('zaposlen') == 0) {
              this.form['mother_D117'].visible = true;
            }
            else if (n.text.search('redovni') > -1 || n.text.search('student') == 0) {
              this.form['mother_D118'].visible = true;
            }
          }
        }
      }
    },
    'form.father_job_status.value': { immediate: true, handler(n) {
        if (typeof n !== 'undefined') {
          if (n.text) {
            this.form['father_D117'].visible = false;
            this.form['father_D118'].visible = false;
            if (!this.init) {
              this.form['father_D117'].value = null;
              this.form['father_D118'].value = null;
            }
            if (n.text.search('odnosu') > -1 || n.text.search('zaposlen') == 0) {
              this.form['father_D117'].visible = true;
            }
            else if (n.text.search('redovni') > -1 || n.text.search('student') == 0) {
              this.form['father_D118'].visible = true;
            }
          }
        }
      }
    },
    'form.mother_parental_leave.value': { immediate: true, handler(n) {
        if (typeof n !== 'undefined') {
          this.form['mother_D119'].visible = false;
          if (!this.init) {
            this.form['mother_D119'].value = null;
          }
          if (n) {
            this.form['mother_D119'].visible = true;
          }
        }
      }
    },
    'form.father_parental_leave.value': { immediate: true, handler(n) {
        if (typeof n !== 'undefined') {
          this.form['father_D119'].visible = false;
          if (!this.init) {
            this.form['father_D119'].value = null;
          }
          if (n) {
            this.form['father_D119'].visible = true;
          }
        }
      }
    },
    'form.family_child_no.value.value': { immediate: true, handler(n) {
        if (typeof n !== 'undefined') {
          this.form['family_D112'].visible = false;
          if (!this.init) {
            this.form['family_D112'].value = null;
          }
          if (n>=this.p60) {
            this.form['family_D112'].visible = true;
          }
        }
      }
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash},
        // dummy variable to handle null visible_condition field
        _at: { value: true }
      },
      //
      from_a: null,
      child_name: null,
      child_surname: null,
      child_pin: null,
      email: null,
      document_list_m: null,
      document_list_f: null,
      document_list_o: null,
      child_family_status: {},
      p57: null,
      p60: null,
      p94: null,
      showForm: false,
      outlined: false,
      siblingVisible: false,
      steps: null,
      init: true
    }
  },

  components: {
    FileUpload,
    TheChildInfo,
    ThePageTitle,
    CitySearch
  }
};

</script>