<template>
  <v-row class="justify-center">
    <div class="pa-3 mb-4 elevation-6 rounded fluid nbr-mw800 text-center text-h5 blue lighten-5">
      <span>{{ name }} {{ surname }}</span><br>
      <span>OIB: {{ pin }}</span>
    </div>
  </v-row>
</template>

<script>
export default {
  props: [ 'name', 'surname', 'pin', 'email' ],
};
</script>