<template>
  <v-dialog :value="visible" width="500" persistent>
    <v-card class="pb-2">
      <v-card-title class="text-h5 grey lighten-2" v-html="title" />
      <v-card-text class="pt-6" v-html="text" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="onCloseClick"
        >U redu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: [ 'visible', 'title', 'text' ],
  methods: {
   onCloseClick() {
    this.$emit('onCloseClick');
   }
  }
};

</script>