import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import hr from 'vuetify/lib/locale/hr'

export default new Vuetify({
  lang: {
    locales: { hr },
    current: 'hr',
  },
});
